import domReady from '@roots/sage/client/dom-ready';
import Router from './utils/router';
import routes from './routes';
import bootstrapAlpine from './bootstrap/alpine';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';
import mediumZoom from 'medium-zoom';
gsap.registerPlugin(ScrollTrigger);
/** Populate Router instance with DOM routes */
const Routes = new Router(routes);
/**
 * External Dependencies
 */
Alpine.plugin(intersect);
Alpine.plugin(collapse);
/**
 * Application entrypoint
 */
domReady(async () => {
    // application code
    Routes.loadEvents();
    Alpine.start();
    bootstrapAlpine();
    mediumZoom('[data-zoomable]', {
        margin: 100,
        background: '#EFEFEF',
    });
});
/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(console.error);
}
