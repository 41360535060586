import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import { gsap } from 'gsap';
import AOS from 'aos';
import aniElData from './construction-planning.json';
import lottie from 'lottie-web';
import { drawMap } from '../components/kakaomap';
export default {
    containerInit() {
        AOS.init();
    },
    init() {
        this.containerInit();
        new Swiper('#hero-section', {
            // Optional parameters
            autoplay: {
                delay: 5000,
            },
            effect: 'fade',
            modules: [Autoplay, EffectFade],
        });
        // section3
        const section3 = document.querySelector('#section3');
        const aos = gsap.utils.toArray('[data-gsap-aos]');
        // aos의 data-gsap-index를 기준으로 정렬
        aos.sort((a, b) => a.dataset.gsapIndex - b.dataset.gsapIndex);
        gsap.from(aos, {
            duration: 1,
            opacity: 0,
            yPercent: 20,
            ease: 'back.out(0.75)',
            stagger: 0.2,
            scrollTrigger: {
                trigger: section3,
                markers: process.env.NODE_ENV === 'development',
                start: 'top 60%',
                end: 'bottom 20%',
                toggleActions: 'play none none reset', // Play the animation once when trigger point is reached
            },
        });
        // section4
        const aniEl = document.querySelector('#lottie-ani1');
        console.log(aniElData);
        lottie.loadAnimation({
            container: aniEl,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: aniElData, // the path to the animation json
        });
        // section5
        const mapNavigator = document.querySelector('#map-navigator');
        drawMap(mapNavigator, 36.98921064065363, 127.4726450198371, '한솔플랜트');
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
