import aniElData from './construction-planning.json';
import lottie from 'lottie-web';
export default {
    containerInit() { },
    init() {
        console.log('test');
        this.containerInit();
        const aniEl = document.querySelector('#lottie-ani1');
        console.log(aniElData);
        lottie.loadAnimation({
            container: aniEl,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: aniElData, // the path to the animation json
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
