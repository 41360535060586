import header from '../components/header';
export default {
    containerInit() {
        console.log(`






                                                               .=-
                                .-==++==:.       .::::::::.   *%+.
                              .=++++++++++-    .::::::::::::
                             -+++++++++++++:   :::::-+*=:::::
                           -+++++++++++++++-  .:::::-%%#:::::.
                         :+++++++++++++++++-  .::::::::::::::.
                       :=++++++++++++++++++-  .--------------:
                     :=++++++++++++++++++++-  +@@@@@@@@@@@@@@*
                   .=++++++++++++++++++++++-  +@@@@@@@@#*****+.........
                 .=++++++++++++++++++++++++-  +@@@@@@#=-::::::::::::::---.
               .=++++++++++++++++++++++++++-  :---------:..............::--
              .++++++++++++++++++++++++++++-  -+++++++++-::..............:-:
              -++++++++++++++++++++++++++++-  +@@@@@@@@@%+--:.............-:
              :++++++++++++++++++++++++++++-  +@@@@@@@@@@@=:--:..........:-.
               -+++++++++++++++++++++++++++-  +%%%%%%%%%+.   .--::.....:--:
                .-=+++++++++++++++++++++++=.  .::::::.         .:------:.

                  .:::::::::::::::::::::::.    .:::::::::::::::::::::::.
                -=+++++++++++++++++++++++++:  :++++++++++++++++++++++++++-
               =+++++++++++++++++++++++++++-  -+++++++++++++++++++++++++++=.
              -++++++++++++++++++++++++++++-  -++++++++++++++++++++++++++++-
              :++++++++++++++++++++++++++++-  -++++++++++++++++++++++++++++-
               =+++++++++++++++++++++++++++-  -+++++++++++++++++++++++++++=
                :=+++++++++++++++++++++++++-  -+++++++++++++++++++++++++=:
                  :++++++++++++++++++++++++-  -++++++++++++++++++++++++-
                    :++++++++++++++++++++++-  -++++++++++++++++++++++-
                      -++++++++++++++++++++-  -++++++++++++++++++++-.
                        -++++++++++++++++++-  -++++++++++++++++++=.
                         .-++++++++++++++++-  -++++++++++++++++=.
                           .=++++++++++++++-  :++++++++++++++=:
                             .=++++++++++++.  .++++++++++++=:
                               :=++++++++=.    .=++++++++=:
                                 .:----:.         :----:.







      `);
        console.log('%c' + 'made by beeclover' + '%c' + '\nhttps://github.com/beeclover', 'color: #169B62; -webkit-text-stroke: 2px #094B2F; font-size: 80px; font-weight: bold; font-family: Pretanded;', 'color: #094B2F; font-size: 18px; font-family: Pretanded;');
    },
    init() {
        header();
        // container init
        this.containerInit();
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
